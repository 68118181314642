import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { POWService } from '../../modules/creator/pow.service';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-track-pow',
  templateUrl: './track-pow.component.html',
  styleUrls: ['./track-pow.component.scss']
})
export class TrackPowComponent implements OnInit {
  @ViewChild('cardToDownload') cardToDownload: ElementRef;
  id: string;
  powData: any;
  date: any;
  intensityPer: string;

  constructor(
    private route: ActivatedRoute,
    private powService: POWService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    // const cardElement = this.cardToDownload.nativeElement;
    // cardElement.style.display = 'none';
    this.getPOW();
  }

  getPOW() {
    this.powService.getSinglePOW(this.id).subscribe({
      next: (res: any) => {
        this.powData = res?.data;
        this.intensityPer = ((res?.data?.intensity / 5) * 100).toFixed(2) + '%';
      },
      complete: () => {
        const timestamp = new Date(this.powData.created_at)
        // const timestamp = new Date("2023-03-27T11:19:50.000000Z");
        this.date = `${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear().toString()}`;
      }

    })
  }


  downloadImage() {
    const div = document.getElementById('cardToDownload');
    const heading = div.querySelector('h2');
    // heading.style.padding = '20px';
    div.style.padding = '20px';
    // const il = document.getElementById('il');
    // il.style.background = 'linear-gradient(30.49deg, #E71E84 7.52%, #F39028 86.77%)';
    html2canvas(div, {
      useCORS: true,
      backgroundColor: '#101010'
    }).then(canvas => {
      // Convert canvas to image
      const image = canvas.toDataURL('image/png');
      const newTab = window.open();
      newTab.document.write('<img src="' + image + '"/>');
      // Create a download link
      const link = document.createElement('a');
      link.download = `${this.date}.png`;
      link.href = image;
      link.click();
      //   cardElement.style.display = 'none'; // show the card element again
    });
    //     const cardElement = this.cardToDownload.nativeElement;
    //     cardElement.style.display = 'block'; // hide the card element
    //     html2canvas(cardElement).then((canvas) => {
    //       // Convert canvas to image
    //       const image = canvas.toDataURL('image/png');

    //       const newTab = window.open();
    // newTab.document.write('<img src="' + image + '"/>');

    //       // Create a download link
    //       const link = document.createElement('a');
    //       link.download = `${this.date}.png`;
    //       link.href = image;
    //       link.click();
    //     //   cardElement.style.display = 'none'; // show the card element again
    //     });
  }


}
function saveAs(image: string, arg1: string) {
  throw new Error('Function not implemented.');
}

