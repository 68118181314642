import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService, UserType } from '../auth';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { SettingsService } from '../account/settings.service';
import { TokenService } from '../auth/services/token.service';
import { PermissionService } from '../auth/services/permission.service';
import { EventService } from '../account/events.service';
import { UserModels } from '../account/models/user.model';
import { LandingPageService } from './landing-page.service.spec';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  private unsubscribe: Subscription[] = [];
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  user$: Observable<UserType>;
  viewCreatorSup: boolean;
  total_referred: any;
  addForm: any = {
    coin: null,
    status: null,
    amount: null,
  };
  userdetails: any;
  referral_link: any;
  page: any = 1;
  constructor(private settings: SettingsService,
    private token: TokenService,
    private landing: LandingPageService,
    private permission: PermissionService,
    private evtSvc: EventService, private clipboard: Clipboard) {
    this.isLoading$ = this.settings.isLoading$;

    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);

    this.permission.getUserByToken().subscribe({
      next: (res) => {
        const a = this.token.getUser();

        this.checkManage(a?.permissions);
      },
    });
  }

  checkManage(a: any) {
    const a1 = a.find((obj: any) => {
      return obj.name === 'view_creator_supporters';
    });
    this.viewCreatorSup = !!a1;
  }
  isVerified = true;
  userDetails: UserModels | any;
  isLoading$: Observable<boolean>;
  ngOnInit(): void {
    this.user$ = this.settings.currentUserSubject.asObservable();
    this.getUserDetails();
    this.getReferralLink();
    this.getReferrals()
    this.getUser();
    this.userdetails = JSON.parse(localStorage.getItem('userdetails') || '{}');
    this.addForm.coin_symbol = this.userdetails.permission_coin;

    this.evtSvc.childEventListner().subscribe((info) => {
      // //console.log(info); // here you get the message from Child component
      if (info === 'true') {
        this.getUser();
      }
    });
  }

  getReferrals() {
    const refSubsc = this.settings.referrals(this.page).subscribe({
      next: (res: any) => {
        this.total_referred = res.data.total_referred
      },
    });
    this.unsubscribe.push(refSubsc);
  }
  submit(f: any) {
    console.log(1);
    this.landing.addLandPage(f).subscribe({
      next: (res: any) => {
        if (res.success === true) {
          Swal.fire('Success', res.message, 'success');
          // this.alert.success(res.message);
          this.getReferralLink();
        }
      },
      error: (err: any) => {
        //console.log(err);
        Swal.fire('Failed!', err, 'error');
        // this.alert.danger(err);
        // this.modalService.dismissAll();
      },
    });
  }
  getReferralLink() {
    const refSubsc = this.landing.getReferralLink().subscribe({
      next: (res: any) => {
        this.referral_link = res.data
        if (res.data.length > 0) {
          this.addForm = res.data[0]
        }
      },
    });
    this.unsubscribe.push(refSubsc);
  }

  getUserDetails() {
    const detailsSubscr = this.settings.getUserDetails().subscribe({
      next: (res: UserModels) => {
        // this.userDetails = res;
        //console.log(res);
        if (res.email_verified_at == null) {
          this.isVerified = false;
        }
      },
    });
    this.unsubscribe.push(detailsSubscr);
  }

  getUser() {
    this.isLoading$$.next(true);
    const b = setInterval(() => {
      const a = this.settings.getUser();
      if (a !== undefined) {
        this.isLoading$$.next(false);
        clearInterval(b);
      }
      // //console.log(a);
      this.userDetails = a;
    }, 1000);
  }

  refresh(user: any) {
    // console.log(address)
    var address
    address = user.custodial_wallet
    this.isLoading$$.next(true);
    this.settings.refresh(address).subscribe({
      next: (res: any) => { },
      complete: () => {
        Swal.fire(
          'Success',
          'Transactions refreshed successfully.',
          'success'
        );
        this.isLoading$$.next(false);
      },
      error: (err: any) => {
        Swal.fire(
          'Error',
          err,
          'error'
        );
        this.isLoading$$.next(false);
      }
    });
  }


  userid(user: any) {
    // console.log(address)

    let userid = user.id
    this.isLoading$$.next(true);
    this.settings.refreshRoles(userid).subscribe({
      next: (res: any) => { },
      complete: () => {
        Swal.fire(
          'Success',
          'Roles refreshed successfully.',
          'success'
        );
        this.isLoading$$.next(false);
      },
      error: (err: any) => {
        Swal.fire(
          'Error',
          err,
          'error'
        );
        this.isLoading$$.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  copy(code: string) {
    this.clipboard.copy(code);

    Swal.fire('Copied', '', 'success')
  }

}
