import { TokenService } from '../auth/services/token.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
const api = `${environment.apiUrl}`;

export type UserType = any | undefined;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})

export class SupporterService implements OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserDetails().subscribe();
    this.unsubscribe.push(subscr);

    // this.getCreatorData();
  }

  getUserDetails(): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user`, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      })
    );
  }


  getCreatorAllSupporters() {
    this.isLoadingSubject.next(true);
    return this.http
      .get(`${api}/solana/get-all-creator-supporters`, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  getCreatorSupporters(page: any, key: any, direction: any, search: string) {
    this.isLoadingSubject.next(true);
    return this.http
      .get(
        `${api}/solana/get-ecosystem-supporters?page=${page}&sort_key=${key}&direction=${direction}&search=${search}`,
        httpOptions
      )
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  getecoinfo(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/ecosystem-info`).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getecoinfo2(): Observable<any> {
    const httpOp = {
      headers: new HttpHeaders({ 'ecosystem': window.location.origin }),
    };
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/ecosystem-info-public`, httpOp).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getAllSegments() {
    return this.http.get(`${api}/user/segments`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  swapBadgePositions(data) {
    return this.http.post(`${api}/exchange/nft-badges/swap-position`, data, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }


  view_badge() {
    return this.http.get(`${api}/exchange/nft-badges`, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  store_badge(data) {
    return this.http.post(`${api}/exchange/nft-badges/store`, data, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  update_badge(data, id) {
    return this.http.post(`${api}/exchange/nft-badges/update/${id}`, data, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))

    )
  }

  delete_badge(id) {
    return this.http.delete(`${api}/exchange/nft-badges/delete/${id}`, httpOptions).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
