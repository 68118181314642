import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SegmentsService } from 'src/app/modules/segments/segments.service';
import { TowerService } from '../tower.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPopupComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() editTowerModal: boolean = false;
  @Input() editTowerVal: any;
  @Input() iframeSrc: string = '';
  @Input() reloadTower: any;
  @Output() modalClosed = new EventEmitter<void>();
  evenForm: FormGroup;
  allSegments: any = [];
  traits: any = [];
  ShowFilter = true;
  towerTitle: string = '';
  towerDescription: string = '';
  floorPrice: number;
  floorValue: number;
  selectedSegment: string = '';
  selectedSegmentsArrays: any[] = [];
  selectedSegmentsArray: any[] = [];
  selectedSegmentsName: any[] = [];
  selectedSegmentsNameArray: string[] = [];
  requiredGiraffe: number = 0;
  requiredGary: number = 0;
  towerLink: string = '';
  towerId: string = '';
  dropdownSettings: any = {};
  dropdownSettings3: IDropdownSettings;
  closeDropdownSelection = true;
  towerData: {
    link: string,
    name: string,
    floorPrice: number,
    floorValue: number,
    description: string,
  };
  contracts: any;
  NFTTraits: { key: string; value: unknown; }[];
  traitValue: any;
  selectedProp: any;
  test: boolean = false;
  customFields: any = [];
  allSegmentsFilterTypes: any = [];
  dropdownList: any = [];
  segmentDetails: any = {}
  filter: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editTowerVal) {

      const segmentIds = this.editTowerVal?.segment_ids || [];

      const numbersArray = segmentIds.map(str => Number(str));

      for (const segmentId of numbersArray) {
        const foundSegment = this.allSegments.find(segment => segment.id === segmentId);

        if (foundSegment) {
          this.selectedSegmentsName.push(foundSegment.name);
          this.selectedSegmentsArray.push(foundSegment.id);
        }
      }

      this.towerTitle = this.editTowerVal?.name || '';
      this.towerDescription = this.editTowerVal?.description || '';
      this.floorPrice = this.editTowerVal?.trait_value || null;
      this.floorValue = this.editTowerVal?.trait_type || null;
      this.selectedSegment = this.editTowerVal?.segment_ids || '';
      this.requiredGary = this.editTowerVal?.no_of_gary_required || 0
      this.requiredGiraffe = this.editTowerVal?.no_of_giraffe_required || 0
      this.towerLink = this.editTowerVal?.link || '';
      this.towerId = this.editTowerVal?.id || '';

    }
  }

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private segmentService: SegmentsService,
    private service: TowerService, private router: Router,
  ) {
  }

  ngOnInit(): void {
    console.log(this.allSegments)
    this.listAllSegments()
    this.getTraittypeandValue()


    this.dropdownList = [
      { id: 1, name: 'Mumbai' },
      { id: 2, name: 'Bangaluru' },
      { id: 3, name: 'Pune' },
      { id: 4, name: 'Navsari' },
      { id: 5, name: 'New Delhi' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings3 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection,
      // "limitSelection": 1,
      searchPlaceholderText: 'Search ',
      noDataAvailablePlaceholderText: 'No segment available',
      noFilteredDataAvailablePlaceholderText: 'Search again',
      showSelectedItemsAtTop: true
    }
  }

  toggleCloseDropdownSelection() {
    console.log(this.closeDropdownSelection)
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  cancel() {
    this.evenForm.reset()
    this.router.navigateByUrl('/sc/tower')
  }

  reload() {
    this.router.navigateByUrl('/sc/tower')
  }

  getContracts() {
    this.service.getContracts().subscribe({
      next: (res: any) => {
        this.contracts = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getTraittypeandValue() {
    this.service.getTraittypeandValue().subscribe({
      next: (res: any) => {
        const traits = res.data
        this.NFTTraits = Object.entries(traits).map(([key, value]) => ({ key, value }));
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  check(data: any) {
    const targetKey = data;
    const targetObject = this.NFTTraits.find(obj => obj.key === targetKey);
    const targetValue = targetObject ? targetObject.value : undefined;
    this.traitValue = targetValue
    this.floorPrice = targetValue[0]

  }

  checkType(event: any, i: any) {
    this.selectedProp = null
    this.filter = event
    this.customFields[i] = this.allSegmentsFilterTypes.find((element: any) => element.slug == event)
    if (this.customFields[i] != undefined) {
      this.test = true
    }
  }

  createTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      trait_value: this.floorPrice,
      trait_type: this.floorValue,
      segment_ids: this.selectedSegmentsArray,
      link: this.towerLink,
      no_of_gary_required: this.requiredGary,
      no_of_giraffe_required: this.requiredGiraffe,
    };

    this.service.createTower(payload).subscribe({
      next: (res: any) => {
        this.towerData = res.data
        Swal.fire('success', res.message, 'success')
        this.showModal = false;
      },
      error: (err: any) => {
        Swal.fire('Failed to create tower', err, 'error')
      },
      complete: () => {
        this.showModal = false;
        Swal.fire({
          title: '<strong>Success</strong>',
          icon: 'success',
          html:
            ``,
          text: 'Tower Created Successfully',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Back to tower',

        }).then((result) => {
          if (result.isConfirmed) {
            this.reloadTower = result
            this.reload()
            this.modalClosed.emit();
            this.showModal = !this.showModal;
            this.towerTitle = '';
            this.towerDescription = '';
            this.floorPrice = null;
            this.floorValue = null;
            this.selectedSegment = '';
            this.selectedSegmentsArray = [];
            this.requiredGary = 0;
            this.requiredGiraffe = 0;
            this.towerLink = '';
          }
          else if (result.isDismissed) {
            this.modalClosed.emit();
            this.reloadTower = result
            this.showModal = !this.showModal;
          }
          else {
            this.cancel()
          }
        })
        this.reloadTower = true
        this.showModal = !this.showModal;
      }
    })

  }

  handleUpdateTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      trait_type: this.floorValue,
      trait_value: this.floorPrice,
      segment_ids: this.selectedSegmentsArray,
      link: this.towerLink,
      no_of_gary_required: this.requiredGary,
      no_of_giraffe_required: this.requiredGiraffe,
    };

    this.service.updateTower(this.towerId, payload).subscribe({
      next: (res: any) => {
        this.towerData = res.data
        Swal.fire('success', res.message, 'success')
      },
      error: (err: any) => {
        Swal.fire('Failed to update tower', err, 'error')
      },
      complete: () => {
        Swal.fire({
          title: '<strong>Success</strong>',
          icon: 'success',
          html:
            ``,
          text: 'Tower Updated Successfully',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          // confirmButtonText: 'Publish to Discord',
          cancelButtonText: 'Return Home',

        }).then((result) => {
          if (result.isConfirmed) {
            this.reloadTower = result
            this.reload()
            this.modalClosed.emit();
            this.showModal = !this.showModal;
            this.showModal = false
            localStorage.removeItem('towerVal')
            this.towerTitle = '';
            this.towerDescription = '';
            this.floorPrice = null;
            this.floorValue = null;
            this.selectedSegment = '';
            this.selectedSegmentsArray = [];
            this.requiredGary = 0;
            this.requiredGiraffe = 0;
            this.towerLink = '';
          } else if (result.isDismissed) {
            this.modalClosed.emit();
          } else {
          }
        })
        this.reloadTower = true
        this.showModal = !this.showModal;
      }
    })
  }

  initForm() {
    this.evenForm = this.fb.group({
      towerLink: ['', Validators.required],
      towerTitle: ['', Validators.required],
      towerDescription: [''],
      floorPrice: ['', Validators.required],
      floorValue: ['', Validators.required],
      selectedSegment: ['', Validators.required],
      segments: [null],

    })
  }

  closeModal() {
    // this.modalClosed.emit();
    this.showModal = !this.showModal;
    this.editTowerModal = false
    localStorage.removeItem('towerVal')
    this.towerTitle = '';
    this.towerDescription = '';
    this.floorPrice = null;
    this.floorValue = null;
    this.selectedSegment = '';
    this.selectedSegmentsArray = [];
    this.selectedSegmentsName = [];
    this.requiredGary = 0
    this.requiredGiraffe = 0
    this.towerLink = '';
  }

  safeIframeSrc(): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }

  onTitleChange(e: any) {
    this.towerTitle = e.target.value
  }

  onDescChange(e: any) {
    this.towerDescription = e.target.value
  }

  onPriceChange(e: any) {
    this.floorPrice = e.target.value
  }

  onPriceValueChange(e: any) {
    this.floorValue = e.target.value
  }

  onGiraffeChange(e: any) {
    this.requiredGiraffe = e.target.value
  }

  onGaryChange(e: any) {
    this.requiredGary = e.target.value
  }

  onLinkChange(event: any) {
    const inputValue = (event.target as HTMLInputElement).value;

    if (!inputValue.startsWith('https://')) {
      this.towerLink = 'https://' + inputValue;
    }
  }

  onSegmentSelect(val: any) {
    this.selectedSegmentsArray = this.selectedSegmentsName.map(obj => obj.id);

  }

  onSelectAll(val: any) {
    this.selectedSegmentsArray = val.map(obj => obj.id);
  }

  removeSelectedSegment(item: any) {
    const index = this.selectedSegmentsName.indexOf(item);
    if (index !== -1) {
      this.selectedSegmentsName.splice(index, 1);
      this.selectedSegmentsArray.splice(index, 1);

      this.selectedSegment = ''
    }
  }

  listAllSegments() {
    this.segmentService.getAllSegments().subscribe({
      next: (res: any) => {
        this.dropdownList = res.data
        console.log(this.dropdownList)
        this.allSegments = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

}
