import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor {
  private authLocalStorageToken = `user`;
  private authdetailsLocalStorageToken = `userdetails`;
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An unknown error occurred';
        if (error.status === 401 || error.status === 403) {
          // Log the user out or perform any other action
          const returnUrl = location.pathname;
          localStorage.removeItem(this.authLocalStorageToken);
          localStorage.removeItem(this.authdetailsLocalStorageToken);
          localStorage.removeItem('onboarding_process');
          this.router.navigate(['/auth/login'], {
            queryParams: { return: returnUrl },
          });
        } else


          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            errorMessage = `${error.error.message}`;

            // Check for specific error codes

          }

        console.error(errorMessage);

        return throwError(() => errorMessage);
      })
    );
  }
}
