<div>
  <div class="card">
    <div class="d-flex top_wrapper">
      <h4>Tower</h4>
      <div class="ml-auto btn-styling">
        <button 
          *ngIf="towers.length > 0" 
          type="button" 
          class="btn btn-block btn-gradient mr-1" 
          (click)="handleCreateTower(content)"
        >
          Create Tower
        </button>
    
        <app-modal-popup 
          #modalRef 
          [showModal]="towerModal"
          (modalClosed)="handleModalClosed()"
          [editTowerModal]="editTowerModal"
          [editTowerVal]="editTowerVal"
          [reloadTower]="reloadTower">
        </app-modal-popup>
      </div>
    </div>
    <div *ngIf="towers.length <= 0"
      class="card border-0 card-pd card-xxl-stretch mb-xl-3 text-center align-items-center justify-content-center">
      <div class="card-body h-400px">
        <img src="assets/imgs/empty-tower.png" alt="" srcset="">
        <p class="fs-6">There are no created towers yet.</p>
        <p class="fs-6">Use the Create Tower button to start creating towers.</p>
        <p class="fs-6">
          <button 
            type="button" 
            class="btn btn-block btn-gradient mr-1" 
            (click)="handleCreateTower(content)"
          >
            Create Tower
          </button>
        </p>
      </div>
    </div>
    <div class="card border-0 card-pd card-xxl-stretch mb-xl-3" *ngIf="towers.length > 0">
      <div class="table-responsive">
        <table class="table align-middle table-row-dashed fs-6 gy-5">
          <thead>
            <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
              <th class="ps-7">Tower Name</th>
              <th>Floor Price ($SOL)</th>
              <th>Segment</th>
              <th>Link </th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of towers  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
              <td class="ps-7 name_wrap">
                {{ item.name }}
              </td>
              <td class="ps-7"> 
                <span *ngIf="item.floor_price > 0; else noListings">{{ item.floor_price | number: '0.0-2' }}</span>
                <ng-template #noListings>No Listings</ng-template>
              </td>
              <td *ngFor="let segment of item.segment_ids" class="ps-7"> {{ getSegmentById(segment) }} </td>
              <td  class="ps-7 linkStyling text-white"> 
                <a [href]="item.link" target="_blank" rel="noopener noreferrer">
                  {{ truncateDescription(item.link) }} 
                </a>
              </td>
              <td class="ps-7 descStyling">
                <span class="">
                  {{ truncateDescription(item.description) }}
                </span>
              </td>
              <td class="action_wrap">
                <span class="btn btn-edit" (click)="handleEditTower(content, item)">
                  <svg stroke="#565674" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="icon-sm" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                </span>
                <span class="btn deleteIcon" (click)="handleDeleteTower(item)">
                  <svg stroke="#565674" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="icon-sm" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="towers.length > 0"
      class="d-flex justify-content-center my-pagination"
      [directionLinks]="true"
      [responsive]="true"
      (pageChange)="p = $event"
    >
    </pagination-controls>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" [innerHTML]="editTowerModal ? 'Update Tower' : 'Create Tower'"></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>

  <div class="modal-body my-3">
    <div class="">
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        > 
          Tower Name *
        </label>
        <input 
          type="text" 
          required 
          [(ngModel)]="towerTitle" 
          (change)="onTitleChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="my tower"
           name="title">
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Description *
        </label>
        <textarea 
          [(ngModel)]="towerDescription" 
          required 
          (change)="onDescChange($event)" 
          class="form-control form-control-lg form-control-solid textarea" 
          placeholder="description"
          name="description" 
          rows="4" 
          cols="50">
        </textarea>
      </div>
      <div>
          <label 
          class="col-form-label fw-bold fs-6"
        >
          Segments *
        </label>
        <ng-multiselect-dropdown 
          class="ng cursor"
          [placeholder]="'select segment...'" [settings]="dropdownSettings3"
          [(ngModel)]="selectedSegmentsArray" [data]="allSegments"
          (onSelect)="onSegmentSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Link *
        </label>
        <input 
          type="text" 
          required 
          [(ngModel)]="towerLink" 
          (change)="onLinkChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="https://"
          name="link"
        >
      </div>
      <div class="btn-styling1">
        <div *ngIf="editTowerModal; else createButton">
          <button 
            type="button" 
            class="btn btn-block btn-gradient mr-1 mb-5 mt-5" 
            (click)="handleUpdateTower()"
          >
            Update Tower
          </button>
        </div>
        <ng-template #createButton>
          <button 
            type="button" 
            class="btn btn-block btn-gradient mr-1 mb-5 mt-5" 
            (click)="createTower()"
          >
            Create Tower
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
