import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CollectiblesService } from 'src/app/modules/digital_collectibles/collectibles.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mint',
  templateUrl: './mint.component.html',
  styleUrls: ['./mint.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class MintComponent implements OnInit, OnDestroy {
  hash: string;
  mintpageNFT: any;

  mintForm: FormGroup
  reference: any;
  refresh: NodeJS.Timer;

  constructor(
    private service: CollectiblesService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private fb: FormBuilder
  ) {
    this.hash = this.route.snapshot.paramMap.get('hash');

    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.getMintPageNFT()
    this.initForm();
  }
  ngOnDestroy() {
    // this.unsubscribe.push()
    clearInterval(this.refresh)
  }

  initForm() {
    this.mintForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      mint_page_hash: [this.hash],
    })
  }

  getMintPageNFT() {
    this.service.getNFTMintPagewithMintHashExternal(this.hash).subscribe({
      next: (res: any) => {
        console.log(res);
        this.mintpageNFT = res.data.mint_page;
      },
      error: (err) => {
        Swal.fire('Failed', err, 'error')
        console.log(err);
      }
    })
  }

  transform(value: string): string {
    if (value) {
      const words = value.split('_');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');

    }
  }

  cancel() {
    this.modalService.dismissAll()
    this.mintForm.reset();
  }


  onClick(content) {
    this.modalService.open(content, { size: 'md' }).result
  }

  mint() {
    const modalRef = this.modalService.open(LoaderModalComponent);
    const payload = {
      email: this.mintForm.value.email,
      password: this.mintForm.value.password,
      mint_page_hash: this.hash,
    }

    console.log(payload);

    this.service.mintNftBackground(payload).subscribe({
      next: (res: any) => {
        console.log(res)
        // message: "Verifying on blockhain"
        // reference: "9Wq1Sxp2S7tsy4SgnoEA3mL1EhpivBJfxRRJwSo7aC3x"
        // status: "pending"
        this.reference = res.data.reference
        Swal.fire('', res.data.message, 'info')
        // let address = `https://solscan.io/token/${res.data.new_mint_address}`
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Success',
        //   text: 'Minted Successfully',
        //   // showConfirmButton: false,
        //   html: `
        //   <h4>${res.message}</h4>
        //   <a href="${address}" target="_blank">View on Solscan</a>
        //   `
        // })
        // Swal.fire('success', res.message, 'success');
      },
      error: (err) => {
        Swal.fire('Failed', err, 'error')
        console.log(err);
        modalRef.dismiss();
      },
      complete: () => {
        // modalRef.dismiss();
        // this.modalService.dismissAll()
        this.mintForm.reset();
        this.refresh = setInterval(() => {
          this.getMintStatus(this.reference, modalRef)
        }, 5000);

      }
    })
  }

  getMintStatus(ref, modalRef) {
    // const modalRef = this.modalService.open(LoaderModalComponent);
    this.service.mintStatus(ref).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.data.status === "completed") {
          clearInterval(this.refresh)
          let address = `https://solscan.io/token/${res.data.new_mint_address}`
          modalRef.dismiss();
          this.modalService.dismissAll()
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Minted Successfully',
            // showConfirmButton: false,
            html: `
          <h4>${res.data.message}</h4>
          <a href="${address}" target="_blank">View on Solscan</a>
          `
          })
        } else if (res.data.status === "failed") {
          clearInterval(this.refresh)
          modalRef.dismiss();
          this.modalService.dismissAll()
          Swal.fire('Failed', res.data.message, 'error')
        }
      },
      error: (err) => {
        Swal.fire('Failed', err.message, 'error')
        modalRef.dismiss();
        this.modalService.dismissAll()
      },
      complete: () => { }
    })
  }

}






@Component({
  selector: 'app-loader-modal',
  template: `
  <div class="modal-body modal-center">
  <h3>Please Wait...</h3>
  <br>
  <div class="mt-7">
    <div class="row align-items-center  my-7">
      <div class="col">
        <h4> Minting</h4>
      </div>
      <div class="col text-end">
       <span *ngIf="load4 === false">
        <i class="fa-solid fa-spinner fa-spin-pulse text-primary fa-xl"></i>
       </span>
       <span *ngIf="load4 === true">
        <i  class="fa-regular fa-circle-check text-primary fa-xl"></i>
       </span>
      </div>

    </div>


  </div>

</div>
  `,
  styles: [
    `
     .text.active {
   background-color: var(--kt-white) !important;
   color: var(--kt-text-secondary) !important;

 }

 .text {
   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
   border-radius: 50%;
 }
    `
  ]
})
export class LoaderModalComponent implements OnInit {
  load4: boolean = false;
  constructor(private modalService: NgbModal, config: NgbModalConfig,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  // openModal(): NgbModalRef {
  //   return this.modalService.open(content);
  // }

  closeModal(modalRef: NgbModalRef): void {
    modalRef.dismiss();
  }
}
