import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;
const USER_URL = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {
  }

  // getJupiterCoins() {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `Bearer 12345`,
  //   });
  //   return this.http.get('https://cache.jup.ag/tokens', { headers: httpHeaders })
  // }

  // public methods
  login(payload: any): Observable<any> {
    const origin = window.location.origin
    const httpHeaders = new HttpHeaders({
      ecosystem: `${origin}`,
    });
    return this.http.post<any>(`${API_USERS_URL}/login`, payload, { headers: httpHeaders });
  }

  otplogin(payload: any): Observable<any> {
    const origin = window.location.origin
    const httpHeaders = new HttpHeaders({
      ecosystem: `${origin}`,
    });
    return this.http.post<any>(`${API_USERS_URL}/verify-twofactor`, payload);
  }

  // verify Email  social-connector/user/update-email

  verifyEmail(otp: any, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(`${API_USERS_URL}/otp/verify`, otp, {
      headers: httpHeaders,
    });
  }

  // verify Email  social-connector/user/update-email

  changeEmail(data: any, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.patch<any>(
      `${USER_URL}/user/update-email`,
      data,
      {
        headers: httpHeaders,
      }
    );
  }

  resendOTP(email: string, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(
      `${API_USERS_URL}/otp/resend`,
      { email },
      { headers: httpHeaders }
    );
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/register`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/reset-password`, data);
  }

  getUserByToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${USER_URL}/user`, {
      headers: httpHeaders,
    });
  }
}
