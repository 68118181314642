import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {
  FormBuilder,
  FormGroup,
  Validator,
  Validators,
  FormControl,
} from '@angular/forms';
import { SocialpayService } from 'src/app/modules/social-pay/service/socialpay.service';
import { LayoutService } from 'src/app/_metronic/layout';
import { WalletService } from 'src/app/modules/account/settings/forms/load-giveaway-wallet/wallet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { Transaction } from '@solana/web3.js';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { encodeURL, createQR } from '@solana/pay';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Options } from 'ngx-qrcode-styling';

moment().format();
const api = `${environment.social_pay_baseurl}`;

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit, OnDestroy {
  ref: any;
  respTnx: any;
  signature: any;
  payment_page_details: any;
  initResp: any;
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  private unsubscribe: Subscription[] = [];
  findTnxresp: any;
  hidePay: boolean;
  // isLoading$: Observable<boolean>;


  private subscription: Subscription;

  public dateNow = new Date();
  public dDay: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;
  expiredTime: any;
  payInfo = 'Awaiting Payment'
  getTime$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); isExpired: boolean;
  page_link: any;
  total: any;
  paidSignature: any;
  success_url: any;
  AtaTnx: any;
  spl_token: boolean;
  merchant_fee: number;
  provider_fee: any;
  refresh: NodeJS.Timer;
  model = {
    email: null,
    password: null
  }
  custodial_wallet: any;
  email: null;
  password: null;
  signed: any;
  showLoad: boolean;
  closeResult: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private socialPayService: SocialpayService,
    private modalService: NgbModal,
    // config: NgbModalConfig,
    private layout: LayoutService,
    private walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private loader: LoadingBarService,
    private router: Router
  ) {
    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);
    this.ref = this.route.snapshot.paramMap.get('ref');
    const url = `${api}/transaction/qr/${this.ref}`
    const location = window?.location?.origin
    this.config.data = `solana:${url}`
    // this.config.image = `https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg`
    this.config.image = `${location}/assets/imgs/logo-socialpay.png`
    // const qrCode = createQR(url);
    // console.log(qrCode)
  }
  public config: Options = {
    "width": 300,
    "height": 300,
    "margin": 0,
    "qrOptions": {
      "mode": "Byte",
      "errorCorrectionLevel": "Q"
    },
    "imageOptions": {
      "hideBackgroundDots": true,
      "imageSize": 0.4,
      "margin": 1
    },
    "dotsOptions": {
      "type": "extra-rounded",
      "color": "#6a1a4c"
    },
    "backgroundOptions": {
      "color": "#ffffff"
    },
    // "dotsOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#6a1a4c",
    //     "color2": "#6a1a4c",
    //     "rotation": "0"
    //   }
    // },
    "cornersSquareOptions": {
      "type": "extra-rounded",
      "color": "#000000"
    },
    // "cornersSquareOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#000000",
    //     "color2": "#000000",
    //     "rotation": "0"
    //   }
    // },
    "cornersDotOptions": {
      // "type": "dot",
      "color": "#000000"
    },
    // "cornersDotOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#000000",
    //     "color2": "#000000",
    //     "rotation": "0"
    //   }
    // },
    // "backgroundOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#ffffff",
    //     "color2": "#ffffff",
    //     "rotation": "0"
    //   }
    // }

  }
  private getTimeDifference() {
    this.isLoading$$.next(true);
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    // console.log(this.timeDifference);
    if (this.timeDifference > 0) {
      // Do Something
      this.allocateTimeUnits(this.timeDifference);
    }
    else {
      this.isExpired = true;
      this.isLoading$$.next(false);
      this.subscription.unsubscribe();
      this.secondsToDday = '00'
      this.minutesToDday = '00'
      this.hoursToDday = '00'
      this.daysToDday = '00'
    }

  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    this.isLoading$$.next(false);
  }



  ngOnInit(): void {
    // console.log(this.dDay);
    this.walletservice.initialize();
    this.getRef();
    // const timeSubscr = this.getTime$$
    //   .asObservable()
    //   .subscribe(x => { this.getTimeDifference(); });
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }
  ngOnDestroy() {
    // this.unsubscribe.push()
    this.subscription.unsubscribe();
    clearInterval(this.refresh)
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName: any) {
    this.walletservice.onSelectWallet(walletName);
  }
  fixUnsafe(url: any) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getRef() {
    this.socialPayService.getTnxByRef(this.ref).subscribe({
      next: (res: any) => {
        console.log('paidSignature', res);
        this.initResp = res;
        console.log(this.initResp);
        this.merchant_fee = parseFloat(this.initResp.payment_transaction.amount_charge);
        this.provider_fee = this.initResp.payment_transaction.provider_fee;
        console.log('currency', this.initResp.payment_page.cryptocurrency_type)
        if (this.initResp.payment_page.cryptocurrency_type === 'SOL') {
          this.spl_token = false;
        } else {
          this.spl_token = true;
        }
        this.page_link = this.initResp.payment_page.page_link
        this.expiredTime = moment(this.initResp.payment_transaction.expires_at).format()
        console.log(this.expiredTime);
        this.dDay = new Date(this.expiredTime)
        this.paidSignature = this.initResp.payment_transaction.signature
        // console.log(this.dDay);
        this.total =
          parseFloat(this.initResp.payment_transaction.provider_fee) +
          parseFloat(this.initResp.payment_transaction.amount_charge);
        // this.total = this.total.toFixed(5);
        // if (this.initResp.payment_page.fee_bearer === 'client') {
        //   this.total =
        //     parseFloat(this.initResp.payment_transaction.provider_fee) +
        //     parseFloat(this.initResp.payment_transaction.amount_charge);
        // } else {
        //   this.total = parseFloat(this.initResp.payment_transaction.amount_charge);
        // }
        // this.findTrans()
        // console.log(this.total)
        if (this.initResp.payment_transaction.status === "paid") {
          clearInterval(this.refresh)
          this.hidePay = true
        } else if (this.initResp.payment_transaction.status === 'expired') {
          clearInterval(this.refresh)
          this.isExpired = true;
        } else {
          this.findTrans()
          this.hidePay = false
          // this.isExpired = false;
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

  openModal(content: any) {
    this.showLoad = true
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.showLoad = false
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.showLoad = false
      },
    );
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSubmit() {
    this.email = this.model.email
    this.password = this.model.password
    // console.log(email, password)
    let data = {
      email: this.email
    }
    this.socialPayService.getCustodilWallet(data).subscribe({
      next: (res: any) => {
        console.log(res)
        this.custodial_wallet = res.data.custodial_wallet
      },
      error: (err) => {
        console.log(err)
        Swal.fire('Error', err, 'error')
        // this.modalService.dismissAll()
        this.showLoad = false
      },
      complete: () => {
        this.payWithCustodial()
        this.modalService.dismissAll()
      }
    })
  }

  // payWithCustodial() {

  // }
  payWithCustodial() {
    const state = this.loader.useRef('http');
    this.isLoading$$.next(true);

    const data = {
      sender: this.custodial_wallet,
      splToken: this.spl_token,
      token_address: this.initResp.payment_page.coin.token_address ?? 'SOL',
      merchant_wallet: this.initResp.payment_transaction.payout_address,
      provider_wallet: this.initResp.payment_transaction.provider_address,
      merchant_amount: this.merchant_fee,
      provider_amount: this.provider_fee,
      reference: this.initResp.payment_transaction.transaction_reference,
      merchant_memo: this.initResp.payment_transaction.transaction_memo,
    };
    this.socialPayService.validateAta(data).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.transaction !== undefined) {
          this.AtaTnx = res.transaction
        }
      },
      error: (err) => {
        this.showLoad = false
        console.log(err)
      },
      complete: () => {
        this.getSCtTrnx();
      }
    })

  }

  // payFunction() {
  //   const state = this.loader.useRef('http');
  //   this.isLoading$$.next(true);

  //   const data = {
  //     sender: this.walletservice.wallet.publicKey,
  //     splToken: this.spl_token,
  //     token_address: this.initResp.payment_page.coin.token_address ?? 'SOL',
  //     merchant_wallet: this.initResp.payment_transaction.payout_address,
  //     provider_wallet: this.initResp.payment_transaction.provider_address,
  //     merchant_amount: this.merchant_fee,
  //     provider_amount: this.provider_fee,
  //     reference: this.initResp.payment_transaction.transaction_reference,
  //     merchant_memo: this.initResp.payment_transaction.transaction_memo,
  //   };
  //   this.socialPayService.validateAta(data).subscribe({
  //     next: (res: any) => {
  //       console.log(res)
  //       if (res.transaction !== undefined) {
  //         this.AtaTnx = res.transaction
  //       }
  //     },
  //     error: (err) => {
  //       console.log(err)
  //     },
  //     complete: () => {
  //       this.getSCtTrnx();
  //     }
  //   })
  // }

  payFunction() {
    const state = this.loader.useRef('http');
    this.isLoading$$.next(true);

    const data = {
      sender: this.walletservice.wallet.publicKey,
      splToken: this.spl_token,
      token_address: this.initResp.payment_transaction.coin.token_address,
      merchant_wallet: this.initResp.payment_transaction.payout_address,
      provider_wallet: this.initResp.payment_transaction.provider_address,
      merchant_amount: this.merchant_fee,
      provider_amount: this.provider_fee,
      reference: this.initResp.payment_transaction.transaction_reference,
      merchant_memo: this.initResp.payment_transaction.transaction_memo,
    };
    this.socialPayService.validateAta(data).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.transaction !== undefined) {
          this.AtaTnx = res.transaction
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        const payload = {
          payment_reference: this.ref,
          sender: this.walletservice.wallet.publicKey
        }
        this.socialPayService.getSocialPayTnx(payload).subscribe({
          next: (res: any) => {
            console.log(res);
            this.respTnx = res.transaction;
          },
          error: (err: any) => {
            Swal.fire('Failed to generate transaction', err, 'error');
            console.log(err);
            this.isLoading$$.next(false);
          },
          complete: async () => {
            let signature;
            let signedTxn = [];
            let tx = new Transaction();
            const blockHash =
              await this.walletservice.connection.getLatestBlockhash();
            tx.feePayer = this.walletservice.wallet.publicKey;
            tx.recentBlockhash = await blockHash.blockhash;
            if (this.AtaTnx !== null && this.AtaTnx !== undefined) {
              signedTxn.push(Transaction.from(Buffer.from(this.AtaTnx, 'base64')));
            }
            signedTxn.push(Transaction.from(Buffer.from(this.respTnx, 'base64')));
            console.log(signedTxn);
            state.start();
            try {
              const signed = await this.walletservice.wallet.signAllTransactions(
                signedTxn
              );

              for (let singleTxn of signed) {
                signature = await this.walletservice.connection.sendRawTransaction(
                  singleTxn.serialize(),
                  { skipPreflight: false, preflightCommitment: 'confirmed' }
                );
                console.log(signature);
                let response =
                  await this.walletservice.connection.confirmTransaction(
                    signature,
                    'confirmed'
                  );
                // console.log(signed);
                this.signature = signature;
              }

            } catch (error: any) {
              this.isLoading$$.next(false);
              state.stop();
              Swal.fire('Failed', error.message, 'error')
              throw new Error(error);
              // Swal.fire('Failed', error, 'error')
            }
            console.log(this.signature);
            state.stop();
            Swal.fire('', 'Please wait while we verify your transaction', 'info')
            this.payInfo = 'Verifying transaction'
            window.close();
            // this.findTrans()


          },
        });
      }
    })

  }

  findTrans() {
    const a = {
      reference: this.ref,
      options: { "limit": 1, "newest": true },
      finality: 'confirmed',
    };
    this.refresh = setInterval(() => {
      this.socialPayService.findTnx(a).subscribe({
        next: (res: any) => {
          this.findTnxresp = res
        },
        error: (err: any) => {
          // console.log(err);
          Swal.fire('Transaction not found', err.error.error, 'error')
        },
        complete: () => {
          if (this.findTnxresp.error === undefined) {
            const data = {
              signature: this.findTnxresp.signature,
              options: {},
              // sender: this.walletservice.wallet.publicKey,
              splToken: this.spl_token,
              token_address: this.initResp.payment_page.coin.token_address ?? 'SOL',
              merchant_wallet: this.initResp.payment_transaction.payout_address,
              provider_wallet: this.initResp.payment_transaction.provider_address,
              merchant_amount: this.merchant_fee,
              provider_amount: this.provider_fee,
              reference: this.initResp.payment_transaction.transaction_reference,
              merchant_memo: this.initResp.payment_transaction.transaction_memo,
              callback: `${api}/transaction/callback/${this.initResp.payment_transaction.transaction_reference}`
            };
            this.socialPayService.validateTnx(data).subscribe({
              next: (res: any) => {
                // this.getRef()
                console.log('testres', res);
                this.paidSignature = res.transaction.signatures[0]
                if (res.payment_status === 'paid') {
                  this.hidePay = true
                  clearInterval(this.refresh)
                  // this.isLoading$$.next(false);
                  if (this.initResp.payment_page.redirect_url !== null) {
                    this.success_url = this.initResp.payment_page.redirect_url
                    Swal.fire({
                      'title': 'Transaction Successful',
                      'html': `Redirecting to <b>${this.success_url}</b>`
                    }).then(() => {
                      let b = this.success_url;
                      let a = document.createElement('a');
                      document.body.appendChild(a);
                      a.href = b;
                      a.click();
                      document.body.removeChild(a);
                      // window.open(this.success_url, '_blank');
                    })
                  } else {
                    Swal.fire('Success', 'Transaction successful', 'success')

                  }
                }
              },
              error: (err: any) => {
                console.log(err);
                Swal.fire(err.error.error, err.error.stack, 'error')
              }
            })
          } else {
            // Swal.fire('Failed', this.findTnxresp.error, 'error')
          }
        },
      });
    }, 10000);
  }

  // Function to get social pay trans details
  getSCtTrnx() {
    const state = this.loader.useRef('http');
    const payload = {
      payment_reference: this.ref,
      sender: this.custodial_wallet
    }
    this.socialPayService.getSocialPayTnx(payload).subscribe({
      next: (res: any) => {
        console.log('just resilt', res);
        this.respTnx = res?.transaction;
      },
      error: (err: any) => {
        console.log('error re naw', err);
      },
      complete: async () => {
        console.log('just resilt');
        let signature;
        let signedTxn = [];
        let tx = new Transaction();
        const blockHash =
          await this.walletservice.connection.getLatestBlockhash();
        tx.feePayer = this.custodial_wallet;
        tx.recentBlockhash = await blockHash.blockhash;
        if (this.AtaTnx !== null && this.AtaTnx !== undefined) {
          signedTxn.push(this.AtaTnx);
        }
        signedTxn.push(this.respTnx);
        console.log(signedTxn);
        state.start();
        let data = {
          transactions: signedTxn,
          email: this.email,
          password: this.password,
          sender: this.custodial_wallet
        }

        this.socialPayService.getSignedTnx(data).subscribe({
          next: (res: any) => {
            console.log('signed re naw', res)
            this.signed = res.data.signedTxns
          },
          error: (err: any) => {
            Swal.fire('Failed to generate transaction', err, 'error');
            console.log(err);
            this.isLoading$$.next(false);
          },
          complete: async () => {
            try {
              const signed = this.signed
              for (let singleTxn of signed) {
                signature = await this.walletservice.connection.sendRawTransaction(
                  Transaction.from(Buffer.from(singleTxn, 'base64')).serialize(),
                  { skipPreflight: false, preflightCommitment: 'confirmed' }
                );
                console.log(signature);
                let response =
                  await this.walletservice.connection.confirmTransaction(
                    signature,
                    'confirmed'
                  );
                // console.log(signed);
                this.signature = signature;
              }
            } catch (error: any) {
              this.isLoading$$.next(false);
              state.stop();
              if (error.message == 'failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1') {
                Swal.fire('Failed', "You do not have sufficient funds to complete the payment!", 'error')
              } else {
                Swal.fire('Failed', error.message, 'error')
              }
              this.showLoad = false
              throw new Error(error);
              // Swal.fire('Failed', error, 'error')
            }
            console.log(this.signature);
            state.stop();
            this.showLoad = false
            Swal.fire('', 'Please wait while we verify your transaction', 'info')
            this.payInfo = 'Verifying transaction'
          }
        })
      }
    });
  }
}
