import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {
  FormBuilder,
  FormGroup,
  Validator,
  Validators,
  FormControl,
} from '@angular/forms';
import { SocialpayService } from 'src/app/modules/social-pay/service/socialpay.service';
import { LayoutService } from 'src/app/_metronic/layout';
import { WalletService } from 'src/app/modules/account/settings/forms/load-giveaway-wallet/wallet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { Transaction } from '@solana/web3.js';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { encodeURL, createQR } from '@solana/pay';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Options } from 'ngx-qrcode-styling';

moment().format();
const api = `${environment.social_pay_baseurl}`;

@Component({
  selector: 'app-inline-checkout',
  templateUrl: './inline-checkout.component.html',
  styleUrls: ['./inline-checkout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InlineCheckoutComponent implements OnInit, OnDestroy {
  ref: any;
  respTnx: any;
  signature: any;
  payment_transaction_details: any;
  initResp: any;
  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean;
  private unsubscribe: Subscription[] = [];
  findTnxresp: any;
  hidePay: boolean;
  // isLoading$: Observable<boolean>;


  private subscription: Subscription;

  public dateNow = new Date();
  public dDay: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;
  expiredTime: any;
  payInfo = 'Awaiting Payment'
  getTime$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); isExpired: boolean;
  page_link: any;
  total: number;
  paidSignature: any;
  success_url: any;
  AtaTnx: any;
  spl_token: boolean;
  merchant_fee: number;
  provider_fee: any;
  refresh: NodeJS.Timer;
  ;




  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private socialPayService: SocialpayService,
    private modalService: NgbModal,
    // config: NgbModalConfig,
    private layout: LayoutService,
    private walletservice: WalletService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private loader: LoadingBarService,
    private router: Router
  ) {
    const loadingSubscr = this.isLoading$$
      .asObservable()
      .subscribe((res) => (this.isLoading = res));
    this.unsubscribe.push(loadingSubscr);
    this.ref = this.route.snapshot.paramMap.get('ref');
    const url = `${api}/transaction/qr/${this.ref}`
    const location = window?.location?.origin
    this.config.data = `solana:${url}`
    // this.config.image = `https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg`
    this.config.image = `${location}/assets/imgs/logo-socialpay.png`
    // const qrCode = createQR(url);
    // console.log(qrCode)
  }
  public config: Options = {
    "width": 200,
    "height": 200,
    "margin": 0,
    "qrOptions": {
      "mode": "Byte",
      "errorCorrectionLevel": "Q"
    },
    "imageOptions": {
      "hideBackgroundDots": true,
      "imageSize": 0.4,
      "margin": 1
    },
    "dotsOptions": {
      "type": "extra-rounded",
      "color": "#6a1a4c"
    },
    "backgroundOptions": {
      "color": "#ffffff"
    },
    // "dotsOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#6a1a4c",
    //     "color2": "#6a1a4c",
    //     "rotation": "0"
    //   }
    // },
    "cornersSquareOptions": {
      "type": "extra-rounded",
      "color": "#000000"
    },
    // "cornersSquareOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#000000",
    //     "color2": "#000000",
    //     "rotation": "0"
    //   }
    // },
    "cornersDotOptions": {
      // "type": "dot",
      "color": "#000000"
    },
    // "cornersDotOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#000000",
    //     "color2": "#000000",
    //     "rotation": "0"
    //   }
    // },
    // "backgroundOptionsHelper": {
    //   "colorType": {
    //     "single": true,
    //     "gradient": false
    //   },
    //   "gradient": {
    //     "linear": true,
    //     "radial": false,
    //     "color1": "#ffffff",
    //     "color2": "#ffffff",
    //     "rotation": "0"
    //   }
    // }

  }
  // public config: Options = {
  //   "width": 200,
  //   "height": 200,
  //   "margin": 0,
  //   "qrOptions": {
  //     "typeNumber": undefined,
  //     "mode": "Byte",
  //     "errorCorrectionLevel": "Q"
  //   },
  //   "imageOptions": {
  //     "hideBackgroundDots": true,
  //     "imageSize": 0.4,
  //     "margin": 3
  //   },
  //   "dotsOptions": {
  //     "type": "classy-rounded",
  //     "color": "#f06348",
  //     "gradient": {
  //       "type": "linear",
  //       "rotation": 0,
  //       "colorStops": [
  //         {
  //           "offset": 0,
  //           "color": "#f06348"
  //         },
  //         {
  //           "offset": 1,
  //           "color": "#a82944"
  //         }
  //       ]
  //     }
  //   },
  //   "backgroundOptions": {
  //     "color": "#ffffff",
  //     "gradient": undefined
  //   },
  //   "cornersSquareOptions": {
  //     "type": "extra-rounded",
  //     "color": "#000000",
  //     "gradient": {
  //       "type": "radial",
  //       "rotation": 0,
  //       "colorStops": [
  //         {
  //           "offset": 0,
  //           "color": "#f00505"
  //         },
  //         {
  //           "offset": 1,
  //           "color": "#a22a2a"
  //         }
  //       ]
  //     }
  //   },
  //   "cornersDotOptions": {
  //     "color": "#720808"
  //   },

  // };

  private getTimeDifference() {
    this.isLoading$$.next(true);
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    // console.log(this.timeDifference);
    if (this.timeDifference > 0) {
      // Do Something
      this.allocateTimeUnits(this.timeDifference);
    }
    else {
      this.isExpired = true;
      this.isLoading$$.next(false);
      this.subscription.unsubscribe();
      this.secondsToDday = '00'
      this.minutesToDday = '00'
      this.hoursToDday = '00'
      this.daysToDday = '00'
    }

  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    this.isLoading$$.next(false);
  }



  ngOnInit(): void {
    // console.log(this.dDay);
    this.walletservice.initialize();
    this.getRef();
    // const timeSubscr = this.getTime$$
    //   .asObservable()
    //   .subscribe(x => { this.getTimeDifference(); });
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }
  ngOnDestroy() {
    // this.unsubscribe.push()
    this.subscription.unsubscribe();
    clearInterval(this.refresh)
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
  }

  onSelectWallet(walletName: any) {
    this.walletservice.onSelectWallet(walletName);
  }
  fixUnsafe(url: any) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getRef() {
    this.socialPayService.getInlineTnxByRef(this.ref).subscribe({
      next: (res: any) => {
        console.log(res);
        this.initResp = res;
        this.merchant_fee = parseFloat(this.initResp.payment_transaction.amount_charge);
        this.provider_fee = this.initResp.payment_transaction.provider_fee;
        if (this.initResp.payment_transaction.currency_type === 'SOL') {
          this.spl_token = false;
        } else {
          this.spl_token = true;
        }
        this.page_link = this.initResp.payment_transaction.page_link
        this.expiredTime = moment(this.initResp.payment_transaction.expires_at).format()
        console.log(this.expiredTime);
        this.dDay = new Date(this.expiredTime)
        this.paidSignature = this.initResp.payment_transaction.signature
        // console.log(this.dDay);
        // if (this.initResp.payment_transaction.fee_bearer === 'client') {
        //   this.total =
        //     parseFloat(this.initResp.payment_transaction.provider_fee) +
        //     parseFloat(this.initResp.payment_transaction.amount_charge);
        // } else {
        //   this.total = parseFloat(this.initResp.payment_transaction.amount_charge);
        // }
        this.total =
          parseFloat(this.initResp.payment_transaction.provider_fee) +
          parseFloat(this.initResp.payment_transaction.amount_charge);
        // this.findTrans()
        // console.log(this.total)
        if (this.initResp.payment_transaction.status === "paid") {
          clearInterval(this.refresh)
          this.hidePay = true
        } else if (this.initResp.payment_transaction.status === 'expired') {
          clearInterval(this.refresh)
          this.isExpired = true;
        } else {
          this.findTrans()
          this.hidePay = false
          // this.isExpired = false;
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

  payFunction() {
    const state = this.loader.useRef('http');
    this.isLoading$$.next(true);

    const data = {
      sender: this.walletservice.wallet.publicKey,
      splToken: this.spl_token,
      token_address: this.initResp.payment_transaction.coin.token_address,
      merchant_wallet: this.initResp.payment_transaction.payout_address,
      provider_wallet: this.initResp.payment_transaction.provider_address,
      merchant_amount: this.merchant_fee,
      provider_amount: this.provider_fee,
      reference: this.initResp.payment_transaction.transaction_reference,
      merchant_memo: this.initResp.payment_transaction.transaction_memo,
    };
    this.socialPayService.validateAta(data).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.transaction !== undefined) {
          this.AtaTnx = res.transaction
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {
        this.socialPayService.generateTnx(data).subscribe({
          next: (res: any) => {
            console.log(res);
            this.respTnx = res.transaction;
          },
          error: (err: any) => {
            Swal.fire('Failed to generate transaction', err, 'error');
            console.log(err);
            this.isLoading$$.next(false);
          },
          complete: async () => {
            let signature;
            let signedTxn = [];
            let tx = new Transaction();
            const blockHash =
              await this.walletservice.connection.getLatestBlockhash();
            tx.feePayer = this.walletservice.wallet.publicKey;
            tx.recentBlockhash = await blockHash.blockhash;
            if (this.AtaTnx !== null && this.AtaTnx !== undefined) {
              signedTxn.push(Transaction.from(Buffer.from(this.AtaTnx, 'base64')));
            }
            signedTxn.push(Transaction.from(Buffer.from(this.respTnx, 'base64')));
            console.log(signedTxn);
            state.start();
            try {
              const signed = await this.walletservice.wallet.signAllTransactions(
                signedTxn
              );

              for (let singleTxn of signed) {
                signature = await this.walletservice.connection.sendRawTransaction(
                  singleTxn.serialize(),
                  { skipPreflight: false, preflightCommitment: 'confirmed' }
                );
                console.log(signature);
                let response =
                  await this.walletservice.connection.confirmTransaction(
                    signature,
                    'confirmed'
                  );
                // console.log(signed);
                this.signature = signature;
              }

            } catch (error: any) {
              this.isLoading$$.next(false);
              state.stop();
              Swal.fire('Failed', error.message, 'error')
              throw new Error(error);
              // Swal.fire('Failed', error, 'error')
            }
            console.log(this.signature);
            state.stop();
            Swal.fire('', 'Please wait while we verify your transaction', 'info')
            this.payInfo = 'Verifying transaction'
            // this.findTrans()


          },
        });
      }
    })

  }

  findTrans() {
    const a = {
      reference: this.ref,
      options: { "limit": 1, "newest": true },
      finality: 'confirmed',
    };
    this.refresh = setInterval(() => {
      this.socialPayService.findTnx(a).subscribe({
        next: (res: any) => {
          this.findTnxresp = res
        },
        error: (err: any) => {
          // console.log(err);
          Swal.fire('Transaction not found', err.error.error, 'error')
        },
        complete: () => {
          if (this.findTnxresp.error === undefined) {
            const data = {
              signature: this.findTnxresp.signature,
              options: {},
              // sender: this.walletservice.wallet.publicKey,
              splToken: this.spl_token,
              token_address: this.initResp.payment_transaction.coin.token_address,
              merchant_wallet: this.initResp.payment_transaction.payout_address,
              provider_wallet: this.initResp.payment_transaction.provider_address,
              merchant_amount: this.merchant_fee,
              provider_amount: this.provider_fee,
              reference: this.initResp.payment_transaction.transaction_reference,
              merchant_memo: this.initResp.payment_transaction.transaction_memo,
              callback: `${api}/transaction/callback/${this.initResp.payment_transaction.transaction_reference}`
            };
            this.socialPayService.validateTnx(data).subscribe({
              next: (res: any) => {
                console.log(res);
                if (res.payment_status === 'paid') {
                  clearInterval(this.refresh)
                  Swal.fire('Success', 'Transaction successfull', 'success')
                  var message = "SocialPayClose scpay" + this.initResp.payment_transaction.transaction_memo + " " + this.initResp.payment_transaction.transaction_reference + "";
                  setTimeout(function () {
                    parent.postMessage(message, '*');

                  }, 3000);
                }
              },
              error: (err: any) => {
                console.log(err);
                Swal.fire(err.error.error, err.error.stack, 'error')
              }
            })
          } else {
          }
        },
      });
    }, 10000);
  }

  close() {
    parent.postMessage('SocialPayClose ', '*');
  }

  paywithwallet() {
    let newWindow: any;
    let url = window?.location?.origin + '/' + `/pay/${this.ref}`;
    console.log(url);
    newWindow = window.open(`${url}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');

    function parseResponse(message: any) {
      let action, split, iframeId, data, isThisIframe;
      return "string" == typeof message && (action = message.split(" ")[0]), action && (split = message.split(" "), iframeId = split[1], data = split[2]), {
        action: action,
        isThisIframe: true,
        data: data,
        iframeId: iframeId,
        split: split
      }

    }

    window.addEventListener(
      'message',
      function (event) {
        if (typeof (event.data) === "string") {
          let message = event.data;
          console.log(event.data)
          let data = parseResponse(message);
          if ("SocialPayClose" == data.action) {
            let reference = data.data;
            // let nmessage = "SocialPayClose scpay" + this.initResp.payment_transaction.transaction_memo + " " + this.initResp.payment_transaction.transaction_memo + "";
            // setTimeout(function() {
            //   parent.postMessage(message, '*');
            //
            // }, 3000);
            newWindow.close();
            Swal.fire('Success', 'Transaction successfull', 'success')

          }
          console.log(data);
        }

      },
      false
    );

  }

}
