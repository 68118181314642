import { ChangeDetectionStrategy, Component, NgZone, OnInit, Inject } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { TokenService } from './modules/auth/services/token.service';
import { SupporterService } from './modules/supporters/supporters.service';
import { DOCUMENT } from '@angular/common';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {
  ecoInfo: any;
  public title: string = '';
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private tokenService: TokenService,
    private creatorSrvc: SupporterService,
    private metaService: Meta,
    private ngZone: NgZone,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.getEcoInfo()
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {

    // this.modeService.init();
  }
  private stripHtmlTags(htmlString: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  }


  getEcoInfo() {
    this.creatorSrvc.getecoinfo2().subscribe({
      next: (res: any) => {
        console.log('eco info', res)
        this.ecoInfo = res.data
      },
      error: (err: any) => {
        setTimeout(() => {
          this.getEcoInfo()
        }, 3000);
      },
      complete: () => {
        this.title = this.ecoInfo?.reward_page_name;
        this.titleService.setTitle(this.title)
        // this.metaServ

        this._document.getElementById('appFavicon').setAttribute('href', this.ecoInfo?.logo);
        const plainTextBioDet = this.stripHtmlTags(this.ecoInfo?.description);
        // console.log(plainTextBioDet)
        // this.metaService.updateTag({ name: 'description', content: plainTextBioDet });
        // this.metaService.updateTag({ name: 'twitter:image', content: this.ecoInfo?.logo });
        // // this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
        // this.ngZone.run(() => {
        //   this.metaService.updateTag({ property: 'og:description', content: plainTextBioDet });
        //   //   this.metaService.updateTag({ property: 'og:url', content: shareableUrl });
        //   this.metaService.updateTag({ property: 'og:title', content: this.ecoInfo?.reward_page_name });
        //   this.metaService.updateTag({ property: 'og:site_name', content: this.ecoInfo?.reward_page_name });
        //   this.metaService.updateTag({ property: 'og:image', content: this.ecoInfo?.logo });
        //   this.metaService.updateTag({ name: 'twitter:image', content: this.ecoInfo?.logo });
        // })


      }
    })
  }
}
