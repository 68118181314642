import { TokenService } from './../auth/services/token.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserModels } from './models/user.model';
import { Router } from '@angular/router';
const api = `${environment.apiUrl}`;

export type UserType = any | undefined;

// export type UserType = UserModels | undefined;

// const userUrl = environment.userUrl;
// const url = 'https://monitor.clubhousetools.xyz/api';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `user`;
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  user: UserModels;
  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserDetails().subscribe();
    this.unsubscribe.push(subscr);
  }

  getUserDetails(): Observable<UserType> {
    return this.http.get<any>(`${api}/user`, httpOptions).pipe(
      map((user: UserType) => {
        if (user) {
          // console.log(user);
          this.currentUserSubject.next(user);
          this.token.saveUserToStorage(user)
          this.user = user;
          this.getUser();
        } else {
          this.logout();
        }
        return user;
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => {
        this.getUser();
        this.isLoadingSubject.next(false);
      })
    );
  }
  getUser() {
    let a = undefined;
    a = this.user;
    if (a !== undefined) {
      return a;
    }
  }

  updateProfile(profile_picture: File, name: any, bio: any) {
    const formData = new FormData();
    // formData.append()
    formData.append('profile_picture', profile_picture);
    formData.append('name', name);
    formData.append('bio', bio);
    // formData.append('city', city);
    // formData.append('country', country);
    // //console.log(formData);
    return this.http.post(`${api}/user/update-profile`, formData);
  }

  updateEcosystemInfo(profile_picture: File, reward_page_name: any, description: any, display_symbol: any, coin_label: any, welcome_email: any, invite_email: any) {
    const formData = new FormData();
    // formData.append()
    formData.append('image_file', profile_picture);
    formData.append('reward_page_name', reward_page_name);
    formData.append('description', description);
    formData.append('display_symbol', display_symbol)
    formData.append('coin_label', coin_label);
    formData.append('welcome_email', welcome_email);
    formData.append('invite_email', invite_email);
    console.log(formData);
    return this.http.post(`${api}/update-ecosystem`, formData).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateSocials(data) {
    return this.http.post(`${api}/user/update-social-media-accounts`, data, httpOptions);
  }
  // Function to refresh api key token
  refreshApiKey() {
    const data = {};
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(`${api}/auth/refresh-token`, data)
      .pipe(
        map(() => { }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  // function to create 2fa
  create2fa() {
    this.isLoadingSubject.next(true);
    return this.http
      .get<any>(`${api}/auth/2fa`)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  remove2fa(payload: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(`${api}/auth/remove-twofactor`, payload)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  verify2fa(payload: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(`${api}/auth/2fa-verify`, payload)
      .pipe(
        map(() => { }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  changeEmail(data: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http
      .patch<any>(`${api}/social-connector/user/update-email`, data)
      .pipe(
        map(() => { }),

        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  updatePassword(data: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.patch<any>(`${api}/user/change-password`, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // Get all universal roles

  getUniversalRoles(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/social-roles`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  createUniversalRole(payload: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(`${api}/user/social-roles`, payload)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  updateUniversalRole(payload: any, role_id: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .patch<any>(`${api}/user/social-roles/${role_id}`, payload)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  unlinkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-twitter`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-twitter-v2`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkTelegram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-telegram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkTelegram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-telegram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkDiscord(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-discord`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // Settings
  getSolanaCoins() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/solana/coin/role`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getAllCoins() {
    return this.http.get(
      environment.base_api + `/exchange/coins/` + environment.network
    );
  }

  linkDiscord(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-discord`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkInstagram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkInstagram(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // instagram business services start
  linkInstagramBusiness(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-business-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkInstagramBusiness(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-business-instagram`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // instagram business services ends

  unlinkClubhouse(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/unlink-clubhouse`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  validateCh(ch_username: string) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(`${api}/user/validate-clubhouse`, { ch_username })
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  linkSolana() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/user/link-solana`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  unlinkSolana() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/user/unlink-solana`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  linkEth() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/user/link-ethereum`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  unlinkEth() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/user/unlink-ethereum`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth'], {
      queryParams: {},
    });
    document.location.reload();
  }

  deactivateAccount() {
    this.isLoadingSubject.next(true);
    return this.http
      .get<any>(`${api}/user/deactivate-account`)
      .pipe(map(() => this.logout()));
  }

  emailNotification(data: any) {
    this.isLoadingSubject.next(true);
    return this.http.post<any>(`${api}/user/generic-notification`, data).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getPortfolio(
    date: string,
    profile_id: any,
    page: any,
    key: string,
    direction: string
  ) {
    this.isLoadingSubject.next(true);
    return this.http
      .get(
        `${api}/social-connector/portfolio?date=${date}&profile_id=${profile_id}&page=${page}&sort_key=${key}&direction=${direction}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  outgoingTnx(page: number, key: string, direction: string) {
    this.isLoadingSubject.next(true);
    return this.http
      .get(
        `${api}/social-connector/portfolio/outgoing-transactions?page=${page}&sort_key=${key}&direction=${direction}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  incomingTnx(page: number, key: string, direction: string) {
    this.isLoadingSubject.next(true);
    return this.http
      .get(
        `${api}/social-connector/portfolio/incoming-transactions?page=${page}&sort_key=${key}&direction=${direction}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  refresh(address: any) {
    return this.http.get(
      `https://gateway.socialconnector.io/api/v2/solana/bfsfa/${address}`
    );
  }

  refreshRoles(userid: any) {
    return this.http.get(
      `https://gateway.socialconnector.io/api/v2/user/poll/${userid}`
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  getMyTeam() {
    return this.http.get(`${api}/access-control/teams/user-teams`).pipe();
  }

  switchTeam(teamId: any) {
    return this.http.get(`${api}/auth/switch-team/${teamId}`).pipe();
  }

  // section for coin notification
  getAllCoinForNotifications() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/solana/coin/all`).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // section for towers
  getAllTowers() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/exchange/tower/`).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // section for towers
  getUsersSegments() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/user/segments`, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  allTowers(page: number, key: string, direction: string) {
    return this.http.get(`${api}/exchange/tower?page=${page}&sort_key=${key}&direction=${direction}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  // coin subcribed to list
  getCoinSubcribedTo() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/social-connector/coin-notifications/all-coins`).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  leaderBoard() {
    let payload = {};
    this.isLoadingSubject.next(true);
    return this.http.patch(`${api}/user/show-leaderboard`, payload).pipe(
      map((res) => {
        return res;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  enableCoinNotification(payload: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(
        `${api}/social-connector/coin-notifications/enable-notifications`,
        payload
      )
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }
  disableCoinNotification(payload: any) {
    this.isLoadingSubject.next(true);
    return this.http
      .post<any>(
        `${api}/social-connector/coin-notifications/disable-notifications`,
        payload
      )
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  getWalletBalances() {
    return this.http
      .get(`${api}/user/wallet-balance`)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  referrals(page: any) {
    return this.http.get(`${api}/user/get-referred-users?page=${page}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  socialTag(data: any) {
    return this.http.post(`${api}/user/create-social-tag`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  walletBalance(page: number, key: string, direction: string) {
    return this.http.get(`${api}/social-connector/portfolio/custodial-balances?page=${page}&sort_key=${key}&direction=${direction}`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  getUserWalletBalances() {
    return this.http.get(`${api}/social-connector/portfolio/custodial-balances-all`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  getSolBalance() {
    return this.http.get(`${api}/user/get-custodial-wallet-balance`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  getSolPrice() {
    return this.http.get(`${api}/solana/get-sol-price`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  sendCoin(data: any) {
    return this.http.post(`${api}/user/send-coin-multi`, data, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  tnx2fa(payload: any) {
    return this.http.post(`${api}/user/enable-transaction-2fa`, payload, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      )
  }

  filterTag(data: any) {
    return this.http.post(`${api}/user/get-user-search`, data, httpOptions)
  }



  getTBCTokens() {
    return this.http.get(environment.base_api + `/exchange/gettbctokens`);
  }
  getAllSwaps() {
    return this.http
      .get(environment.base_api + `/exchange/getallswap`)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getGaryPrice() {
    return this.http.get(
      environment.base_api + '/exchange/getcoininfo/$gary/mainnet'
    );
  }
  getcoinprice2(coin: any) {
    return this.http.get(
      environment.base_api + `/exchange/getcoininfo2/${coin}/mainnet`
    );
  }
  checkResc(data: any) {
    return this.http.get(
      environment.base_api + `/exchange/getswaprestrictions/${data}`
    );
  }


  // Function to get nft
  getNft() {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/social-connector/portfolio/custodial-nft-balances`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // get a single nft
  getNftSingle(token: any) {
    this.isLoadingSubject.next(true);
    return this.http.get(`${api}/social-connector/portfolio/get-single-nft?token=${token}`, httpOptions).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loadLevels() {
    return this.http.get(`${api}/social-connector/portfolio/score-multiplier`, httpOptions)
  }

  // Function to send nft
  sendnft(payload: any) {
    return this.http.post(`${api}/user/send-nft`, payload, httpOptions)
      .pipe(
        map((rs) => {
          return rs;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      )
  }

  getNFTBlockchain(token: any) {
    return this.http.get(`${api}/solana/nfts/naming-nft/${token}`, httpOptions)
  }
  nameGirafe(data, token) {
    return this.http.post(`${api}/solana/nfts/naming-nft/${token}`, data, httpOptions)
  }


  getAllCollections() {
    return this.http.get(`${api}/social-connector/portfolio/user-nft-collections`).pipe()
  }

  getNFTByCollection(address) {
    return this.http.get(`${api}/social-connector/portfolio/nfts-of-collection/${address}`).pipe()
  }


  featureNFT(data) {
    return this.http.post(`${api}/solana/nfts/add-nft-to-featured`, data, httpOptions).pipe()
  }

  unfeatureNFT(data) {
    return this.http.post(`${api}/solana/nfts/remove-nft-from-featured`, data, httpOptions).pipe()
  }

  updateMinAmount(data) {
    return this.http.post(`${api}/user/update-minum-note-amount`, data, httpOptions).pipe()
  }

  getUnknownCollectible() {
    return this.http.get<any>(`${api}/social-connector/portfolio/nfts-without-collectible`, httpOptions).pipe()
  }


  // getCountries() {
  //   const httpOp = {
  //     headers: new HttpHeaders({ "X-CSCAPI-KEY": "API_KEY" }),
  //   };
  //   return this.http.get("https://api.countrystatecity.in/v1/countries", httpOp).pipe()
  // }


  getecoinfo(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/ecosystem-info`).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  stripe() {
    return this.http.get(`${api}/stripe-customer-portal`).pipe()
  }

  sendPreview(data: any) {
    return this.http.post(`${api}/ecosystem-email-preview`, data).pipe()
  }

}

