import { Component, OnInit, Input, ViewChild, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
import { TowerService } from '../tower.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SegmentsService } from 'src/app/modules/segments/segments.service';
import Swal from 'sweetalert2';
// import { biPencilSquare, biTrash } from 'bootstrap-icons/icons';

@Component({
  selector: 'app-towers',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class TowersComponent implements OnInit {
  public towerModal: boolean = false;
  public editTowerModal: boolean = false;
  reloadTower: any;
  editTowerVal: any;
  towers: any;
  per_page: any;
  total: any;
  p: any;
  page = 1;
  key = 'name';
  direction = 'desc';
  disableDrop: boolean;
  towerTitle: string = '';
  dropdownSettings3: IDropdownSettings;
  form: FormGroup;
  showModal: boolean = false;
  iframeSrc: string = '';
  evenForm: FormGroup;
  allSegments: any = [];
  segmentName: any = [];
  traits: any = [];
  ShowFilter = true;
  towerDescription: string = '';
  floorPrice: number;
  floorValue: number;
  enableTraits: boolean = false;
  selectedSegment: string = '';
  selectedSegmentsArrays: any[] = [];
  selectedSegmentsArray: any[] = [];
  selectedSegmentsName: any[] = [];
  selectedSegmentsNameArray: string[] = [];
  requiredGiraffe: number = 0;
  requiredGary: number = 0;
  towerLink: string = '';
  towerId: string = '';
  dropdownSettings: any = {};
  closeDropdownSelection = true;
  towerData: {
    link: string,
    name: string,
    floorPrice: number,
    floorValue: number,
    description: string,
  };
  contracts: any;
  NFTTraits: { key: string; value: unknown; }[];
  traitValue: any;
  selectedProp: any;
  test: boolean = false;
  customFields: any = [];
  allSegmentsFilterTypes: any = [];
  dropdownList: any = [];
  segmentDetails: any = {}
  filter: any;

  @ViewChild(ModalPopupComponent) modalPopup: ModalPopupComponent;

  constructor(private service: TowerService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private segmentService: SegmentsService,
  ) { }


  ngOnInit() {
    this.getAllTowers()

    this.listAllSegments()
    this.getContracts()


    this.dropdownList = [
      { id: 1, name: 'Mumbai' },
      { id: 2, name: 'Bangaluru' },
      { id: 3, name: 'Pune' },
      { id: 4, name: 'Navsari' },
      { id: 5, name: 'New Delhi' }
    ];

    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      singleSelection: true,
      allowSearchFilter: true,
      selectAllText: 'Select All',
      closeDropDownOnSelection: true,
      unSelectAllText: 'UnSelect All',
    };

    this.dropdownSettings3 = {
      idField: 'id',
      textField: 'name',
      singleSelection: true,
      allowSearchFilter: true,
      selectAllText: 'Select All',
      showSelectedItemsAtTop: true,
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search ',
      closeDropDownOnSelection: this.closeDropdownSelection,
      noDataAvailablePlaceholderText: 'No segment available',
      noFilteredDataAvailablePlaceholderText: 'Search again',
    }

  }

  getAllTowers() {
    this.towers = [];
    this.service.getAllTowers(this.page, this.key, this.direction).subscribe({
      next: (res: any) => {
        let data = res.data
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          this.towers.push(element);
        }
      },
      error: (err: any) => { },
      complete: () => { }
    })
  }

  getSegmentById(id: any) {
    const item = this.allSegments.find((item) => item.id === id);

    return item?.name;
  }

  getPage(ev: any) {
    this.page = ev
    this.getAllTowers()
  }

  handleModalClosed() {
    this.getAllTowers();
  }

  handleCreateTower(content: any) {
    this.enableTraits = false
    this.editTowerModal = false
    this.towerTitle = '';
    this.towerDescription = '';
    this.floorPrice = null;
    this.floorValue = null;
    this.selectedSegment = '';
    this.requiredGary = 0
    this.requiredGiraffe = 0
    this.towerLink = '';
    this.towerId = '';
    this.selectedSegmentsName = []
    this.selectedSegmentsArray = [{ id: 0, name: '' }]
    this.modalService.open(content, { backdrop: 'static', centered: true }).result;
  }

  handleEditTower(content: any, data: any,) {

    if (this.floorValue !== null) {
      this.enableTraits = true
    }

    const segmentIds = data?.segment_ids || [];

    const numbersArray = segmentIds.map(str => Number(str));

    for (const segmentId of numbersArray) {
      const foundSegment = this.allSegments.find(segment => segment.id === segmentId);

      if (foundSegment) {
        this.selectedSegmentsArray.push(foundSegment);
        this.selectedSegmentsArrays.push(this.selectedSegmentsArray[0].id);
        this.selectedSegment = this.selectedSegmentsArray[0].id;
      }
    }
    this.editTowerVal = data;
    this.towerTitle = data?.name || '';
    this.towerDescription = data?.description || '';
    this.floorPrice = data?.trait_value || null;
    this.floorValue = data?.trait_type || null;
    this.requiredGary = data?.no_of_gary_required || 0
    this.requiredGiraffe = data?.no_of_giraffe_required || 0
    this.towerLink = data?.link || '';
    this.towerId = data?.id || '';
    this.editTowerModal = true
    this.modalService.open(content, { backdrop: 'static', centered: true }).result;

  }

  clearData() {
    this.editTowerVal = null;
  }

  handleDeleteTower(val) {
    Swal.fire({
      title: '<strong>Warning</strong>',
      icon: 'warning',
      html: ``,
      text: `About to delete ${val.name} tower ?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',

    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name: val.name,
          description: val.description,
          floorPrice: val.floorPrice,
          segment_ids: val.segment_ids,
          link: val.link,
        };

        this.service.deleteTower(val.id, payload).subscribe({
          next: (res: any) => {
            this.towerData = res.data
          },
          error: (err: any) => {
            Swal.fire('Failed to delete tower', err, 'error')
            console.log(err)
          },
          complete: () => {
            Swal.fire({
              title: '<strong>Success</strong>',
              icon: 'success',
              html:
                ``,
              text: 'Tower Deleted Successfully',
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: 'OK',

            })
            this.getAllTowers();
          }
        })
      } else if (result.isDismissed) {
        return;
      } else {
        return;
      }
    })
  }

  toggleCloseDropdownSelection() {
    console.log(this.closeDropdownSelection)
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  cancel() {
    this.evenForm.reset()
    this.router.navigateByUrl('/sc/tower')
  }

  reload() {
    this.router.navigateByUrl('/sc/tower')
  }

  getContracts() {
    this.service.getContracts().subscribe({
      next: (res: any) => {
        this.contracts = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getTraittypeandValue() {
    this.service.getTraittypeandValue().subscribe({
      next: (res: any) => {
        console.log(res.data)
        const traits = res.data
        this.NFTTraits = Object.entries(traits).map(([key, value]) => ({ key, value }));
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }


  check(data: any) {
    const targetKey = data;
    const targetObject = this.NFTTraits.find(obj => obj.key === targetKey);
    const targetValue = targetObject ? targetObject.value : undefined;
    this.traitValue = targetValue
    this.floorPrice = targetValue[0]

    if (this.floorValue !== null) {
      this.enableTraits = true
    }

  }

  checkType(event: any, i: any) {
    this.selectedProp = null
    this.filter = event
    this.customFields[i] = this.allSegmentsFilterTypes.find((element: any) => element.slug == event)
    if (this.customFields[i] != undefined) {
      this.test = true
    }
  }

  createTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      segment_ids: this.selectedSegmentsArrays,
      link: this.towerLink,
    };

    this.service.createTower(payload).subscribe({
      next: (res: any) => {
        this.towerData = res.data
        this.showModal = false;
      },
      error: (err: any) => {
        Swal.fire('Failed to create tower', err, 'error')
      },
      complete: () => {
        this.showModal = false;
        this.getAllTowers()
        this.reload()
        this.modalService.dismissAll('Close click');
        this.showModal = !this.showModal;
        this.towerTitle = '';
        this.towerDescription = '';
        this.floorPrice = null;
        this.floorValue = null;
        this.selectedSegment = '';
        this.selectedSegmentsName = []
        this.selectedSegmentsArray = [];
        this.requiredGary = 0;
        this.requiredGiraffe = 0;
        this.towerLink = '';
        this.reloadTower = true
        this.showModal = !this.showModal;
      }
    })

  }

  handleUpdateTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      segment_ids: this.selectedSegmentsArrays,
      link: this.towerLink,
    };

    this.service.updateTower(this.towerId, payload).subscribe({
      next: (res: any) => {
        this.towerData = res.data
      },
      error: (err: any) => {
        Swal.fire('Failed to update tower', err, 'error')
        this.selectedSegmentsArrays = []
      },
      complete: () => {
        this.getAllTowers()
        this.reload()
        this.modalService.dismissAll('Close click');
        this.showModal = !this.showModal;
        this.showModal = false
        localStorage.removeItem('towerVal')
        this.towerTitle = '';
        this.towerDescription = '';
        this.floorPrice = null;
        this.floorValue = null;
        this.selectedSegment = '';
        this.selectedSegmentsName = [];
        this.selectedSegmentsArray = [];
        this.selectedSegmentsArrays = []
        this.requiredGary = 0;
        this.requiredGiraffe = 0;
        this.towerLink = '';
        this.reloadTower = true
        this.showModal = !this.showModal;
      }
    })
  }

  initForm() {
    this.evenForm = this.fb.group({
      towerLink: ['', Validators.required],
      towerTitle: ['', Validators.required],
      towerDescription: [''],
      floorPrice: ['', Validators.required],
      floorValue: ['', Validators.required],
      selectedSegment: ['', Validators.required],
      segments: [null],

    })
  }

  closeModal() {
    this.towerTitle = '';
    this.towerDescription = '';
    this.floorPrice = null;
    this.floorValue = null;
    this.selectedSegment = '';
    this.requiredGary = 0
    this.requiredGiraffe = 0
    this.towerLink = '';
    this.towerId = '';
    this.selectedSegmentsName = []
    this.selectedSegmentsArray = []
    this.modalService.dismissAll('Close click');
  }

  safeIframeSrc(): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }

  onTitleChange(e: any) {
    this.towerTitle = e.target.value
  }

  onDescChange(e: any) {
    this.towerDescription = e.target.value
  }

  onPriceChange(e: any) {
    this.floorPrice = e.target.value
  }

  onPriceValueChange(e: any) {
    this.floorValue = e.target.value
  }

  onGiraffeChange(e: any) {
    this.requiredGiraffe = e.target.value
  }

  onGaryChange(e: any) {
    this.requiredGary = e.target.value
  }

  onLinkChange(event: any) {
    const inputValue = (event.target as HTMLInputElement).value;

    if (!inputValue.startsWith('https://')) {
      this.towerLink = 'https://' + inputValue;
    }
  }

  onSegmentSelect(selectedItem: any) {
    this.selectedSegmentsArrays = []

    const foundSegment = this.allSegments.find(segment => segment.name === selectedItem.name);

    if (foundSegment) {
      this.selectedSegmentsArray.push(foundSegment);
      this.selectedSegmentsArrays.push(this.selectedSegmentsArray[0].id);
      this.selectedSegment = this.selectedSegmentsArray[0].id;
    }
  }

  listAllSegments() {
    this.segmentService.getAllSegments().subscribe({
      next: (res: any) => {
        this.dropdownList = res.data
        console.log(this.dropdownList)
        this.allSegments = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  shouldTruncate(description: string): boolean {
    return description.length > 35;
  }

  truncateDescription(description: string): string {
    if (this.shouldTruncate(description)) {
      return description.slice(0, 35) + '...';
    } else {
      return description;
    }
  }

}

